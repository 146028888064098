.comment {
  display: flex;
  align-items: center;
  background-color: #eff2f5;
  margin-left: 10px;
  border-radius: 999px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 20px;
}

.comment > input {
  border: none;
  background-color: transparent;
  outline-width: 0;
  margin-left: 20px;
  width: 100%;
}

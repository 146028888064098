.messageSender {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.messageSender__top {
  display: flex;
  padding: 15px;
}

.messageSender__top > input {
  outline-width: 0;
  border: none;
  padding: 5px 20px;
  margin-left: 10px;
  border-radius: 999px;
  background-color: #eff2f5;
  width: 100%;
  cursor: pointer;
}

.messageSender__editor {
  margin-left: 10px;
  width: 100%;
}

.messageSender__bottom {
  display: flex;
  justify-content: right;
  margin-right: 10px;
  margin-bottom: 10px;
}

.messageSender__bottom > button {
  padding: 10px 20px;
  border-radius: 10px;
  margin-right: 10px;
  font-weight: 400;
  border: 2px solid transparent;
  cursor: pointer;
}

.messageSender__bottom > button:first-child {
  border-color: #ff3d34;
  background-color: transparent;
  color: black;
}

.messageSender__bottom > button:last-child {
  background-color: #ff3d34;
  color: white;
  font-weight: 700;
}

.messageSender__bottom > button:first-child:hover {
  background-color: #f7dddc;
}

.messageSender__bottom > button:last-child:hover {
  background-color: #861a14;
}

.post {
  width: 100%;
  margin-top: 25px;
  min-width: 400px;
  max-width: 750px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.post__top {
  display: flex;
  position: relative;
  align-items: center;
  padding: 15px;
  margin-bottom: -10px;
}

.post__avatar {
  margin-right: 10px;
}

.post__bottom {
  margin-bottom: 10px;
  padding: 15px 25px;
}

.post__topInfo > p {
  font-size: small;
  color: gray;
  margin-top: -15px;
}

.post__image > img {
  width: 100%;
}

.post__options {
  border-top: 1px solid lightgray;
  display: flex;
  justify-content: space-evenly;
  font-size: medium;
  color: gray;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.post__option {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.post__option > p {
  margin-left: 10px;
}

.post__option:hover {
  background-color: #eff2f5;
  border-radius: 10px;
}

.post__tags {
  display: flex;
  margin-left: 20px;
  margin-bottom: 20px;
}

.post__tags > button {
  font-size: 16px;
  font-weight: 600;
  color: white;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px;
}

.post__comments {
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgray;
}

.post__comment {
  display: flex;
  margin: 10px;
  margin-top: 20px;
}

.post__commentBody {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  background-color: #f0f2f5;
  padding: 10px;
  border-radius: 20px;
}

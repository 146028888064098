body {
  background-color: #f1f2f5;
}

.app {
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: auto;
  background-color: #f1f2f5;
}

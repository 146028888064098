.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: 100px;
}

.login__logo > .MuiAvatar-root {
  height: 200px;
  width: 200px;
  margin-bottom: 0px;
}

.login > h1 {
  font-size: 40px;
  font-weight: 900;
  color: #ff3d34;
  margin-bottom: 50px;
  font-family: New Century Schoolbook, TeX Gyre Schola, serif;
}

.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signin > h1 {
  font-size: 45px;
}

.login__button > button {
  margin-top: 40px;
  color: black;
  text-transform: none;
  border: 1px solid black;
  width: 400px;
  font-size: 18px;
  margin: 5px;
}

.login__button:hover > button {
  background-color: #edf1f5;
}
